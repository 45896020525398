.action--btn {
    .more--icon{
        font-size: 20px;
        color: #000;
    }
    .action--dropdown {
        position: absolute;
        width: 125px;
        top: 100%;
        background: #fff;
        border-radius: 5px;
        z-index: 1;
        right: 0;
        margin-top: 9px;
        box-shadow: 0px 4px 24.1px 0px rgba(0, 0, 0, 0.15);

        ul {
            display: flex;
            flex-direction: column;

            li {
                display: flex;
                gap: 10px;
                align-items: center;
                font-size: 14px;
                color: #000;
                cursor: pointer;
                padding: 10px 15px;
                transition: 0.3s;

                &:hover {
                    background: var(--lightgray);
                }
            }
        }
    }
}

.area--page{
    @media(max-width:991px){
        padding: 15px;
        padding-bottom: 0;
    }
}