.sidebar--menu {
    transition: 0.3s;
    position: sticky;
    top: 65px;

    @media(max-width:1024px){
        width: 62px;
        position: fixed;
        top: 65px;
        margin-top: 0px;
        background: var(--white);
        z-index: 3;
        padding: 8px;
    }

    .top--logobar {
        display: flex;
        justify-content: end;
        align-items: center;
        padding: 30px 5px;
        padding-top: 6px;
        position: relative;


        .logo--sidebar {
            transition: 0.3s;
        }

        .toggle--bar {
            font-size: 30px;
            cursor: pointer;
            position: absolute;
            right: -25px;
            top: 0;

            @media(max-width:1024px){
                transform: rotate(180deg);
                right: -18px;
            }

            svg{
                @media(max-width:1024px){
                    width: 25px;
                    height: 25px;
                    padding: 7px;
                }
            }
        }
    }

    .login--details {
        color: var(--black);
        border-bottom: 1px solid var(--border);

        .user--name {
            font-size: 20px;
            width: 270px;
        }

        .user--role {
            color: var(--lightcolor);
            width: 270px;
            font-size: 12px;
        }

        .single--name {
            background: var(--black);
            color: var(--white);
            border-radius: 50px;
            height: 45px;
            width: 45px;
            display: none;
            align-items: center;
            justify-content: center;
            font-size: 22px;
            font-weight: 500;
            padding: 10px;

            @media(max-width:1024px){
                display:flex;
            }
        }

        .user--name, .user--role{
            @media(max-width:1024px){
                display:none;
            }
        }
    }

    .management--menu {

        border-bottom: 1px solid var(--border);

        .label--menu {
            color: var(--lightcolor);
            font-size: 12px;
            margin-bottom: 10px;
            padding-left: 12px;

            @media(max-width:1024px){
                display: none !important;
            }
        }

        .menu--bar--list {
            li {
                a {
                    width: 235px;
                    transition: 0.3s;
                    font-size: 14px;
                    font-weight: 600;
                    height: 40px;

                    @media(max-width:1024px){
                        width: 45px;
                        justify-content: center;
                    }

                    svg.menu--icon {
                        font-size: 21px;
                    }

                    &:hover {
                        background-color: var(--black2);
                        color: var(--white);
                    }

                    img {
                        width: 28px;
                        height: 28px;
                    }

                    &:hover img {
                        filter: grayscale(0) invert(1);
                        transition: 0.3s;
                    }

                    span{
                        @media(max-width:1024px){
                            display:none;
                        }
                    }
                    
                }

                a.active {
                    background: var(--black2);
                    color: var(--white);
                    img {
                        filter: grayscale(0) invert(1);
                        transition: 0.3s;
                    }
                }

                .dropdown--items {
                    padding-top: 10px;

                    li {
                        a {
                            background: transparent;
                            color: #000;
                            padding: 3px 15px;
                            display: flex;
                            flex-direction: column;
                            align-items: flex-start;

                            &:after {
                                content: '';
                                width: 0%;
                                height: 1px;
                                background: #000;
                                transition: 0.3s;
                            }

                            &:hover:after {
                                width: 100%;
                            }

                           
                            
                        }
                    }
                }
            }
        }

    }
}

.active--toggle {
    width: 75px;
    transition: 0.3s;

    @media(max-width:1024px){
        width: 223px;
        box-shadow: 5px 0px 7px 2px rgb(0 0 0 / 12%);
        z-index: 333;
    }

    .top--logobar {
        justify-content: center;

        .logo--sidebar {
            width: 0px;
            transition: 0.3s;
        }
    }

    .login--details {
        .single--name{
            display: flex;

            @media(max-width:1024px){
                display:none;
            }
        }
        .user--name, .user--role{
            display:none;

            @media(max-width:1024px){
                display:block;
            }
        }
    }

    .management--menu {

        padding-bottom: 15px;
        margin-bottom: 15px;

        .menu--bar--list {
            li {
                a {
                    width: 45px;
                    justify-content: center;

                    @media(max-width:1024px){
                        width: 208px;
                        justify-content: flex-start;
                    }

                    span{
                        display: none;
                        white-space: nowrap;

                        @media(max-width:1024px){
                            display:block;
                        }
                    }


                    .downarrow-menu {
                        display: none;
                    }
                }
            }
        }

    }

    .hide--content {
        display: none !important;

        @media(max-width:1024px){
            display: block !important;
        }
    }

}


.side--content--area{
    @media(max-width:1024px){
        height: calc(100vh - 65px);
        width: calc(100% - 62px);
    }
}