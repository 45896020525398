.topbar--admin {
    padding: 17px 20px;
    background: var(--black);
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 1px solid var(--black);
    border-top: 0px;
    box-shadow: 2px 4px 7px -4px #00000014;
    z-index: 2;


    .topbar--rightside {
        ul {
            display: flex;
            align-items: center;
            gap: 10px;

            li.menu--item {
                background: #f5f5f5;
                padding: 6px;
                border-radius: 5px;
                cursor: pointer;
                transition: 0.3s;
                position: relative;
                border: 1px solid var(--white);

                &:hover {
                    color: var(--white);
                    background-color: transparent;
                }

                .profile--dropdown {
                    position: absolute;
                    background: var(--white);
                    min-width: 150px;
                    width: 100%;
                    right: 0;
                    border-radius: 5px;
                    top: 100%;
                    margin-top: 17px;

                    ul {
                        display: block;
                        box-shadow: 0px 3px 5px 4px #b5b5b526;

                        li {
                            padding: 10px;
                            width: 100%;
                            transition: 0.3s;
                            color: #1f1f28;
                            border-bottom: 1px solid #ededed;
                            font-size: 16px;

                            &:hover {
                                background: #f5f5f5;
                            }

                            &:last-child {
                                border: none;
                            }
                        }
                    }
                }

                .bell--circle {
                    width: 7px;
                    aspect-ratio: 1;
                    background: #000;
                    border-radius: 50px;
                    position: absolute;
                    top: 5px;
                    right: 6px;
                    border: 1px solid #f5f5f5;
                }
            }

            // li.active--menu {
            //     background-color: #000;
            //     color: #fff;
            // }

            .profile--info {
                line-height: normal;

                small {
                    font-size: 12px;
                    color: #5f5e5e;
                    font-weight: 700;
                }
            }
        }
    }
}

// .active--toggle ~ div > .topbar--admin > .logo--topbar {
//     visibility: visible;
// }

// .active--toggle ~ div > .topbar--admin {
//     justify-content: space-between;
// }

.notification--list {
    max-width: 293px;
    z-index: 333;
    right: 20px;
    padding: 0px;
    margin-top: 0px;

    ul {
        display: flex;
        flex-direction: column;
        gap: 10px;
        list-style: disc;
        padding: 20px 35px;
        z-index: 2;
        position: relative;
        background: #fff;
        border-radius: 10px;
        list-style: none;

        li {
            font-size: 14px;
            position: relative;
            padding-left: 15px;

            &:before {
                content: '';
                width: 5px;
                aspect-ratio: 1;
                background: #000;
                display: block;
                border-radius: 50px;
                min-width: 5px;
                position: absolute;
                top: 8px;
                left: 0px;
            }
        }

        .view--all--notifications {
            padding-left: 0px !important;
            text-align: center;
            padding-top: 15px;
            border-top: 1px solid #efefef;
            cursor: pointer;

            &:before {
                display: none !important;
            }
        }


    }

    &:before {
        content: '';
        width: 26px;
        aspect-ratio: 1;
        background: #000;
        display: block;
        transform: rotate(45deg);
        position: absolute;
        top: -5px;
        right: 17%;
        z-index: 1;
    }
}