.filter--search {
    width: 100%;
    max-width: 500px;

    @media(max-width: 767px) {
        max-width: 100%;
    }
}

.filter--search .filter--submit--btn {
    width: 100%;
    max-width: 120px;
}

.filter--search .filter--input--search {
    width: 100%;

    .input--text {
        width: 100%;

        &:focus-visible {
            border: none;
            box-shadow: none;
            outline: none;
        }
    }

    .css-b62m3t-container {
        max-width: 145px;
        width: 100%;

        .css-13cymwt-control,
        .css-t3ipsp-control {
            border: none;
            box-shadow: none;
        }

        &:hover {
            border: none;
        }

        span.css-1u9des2-indicatorSeparator {
            display: none;
        }
    }

    // .search--icon {
    //     width: 50px;
    // }

}

.modal--popupservice {

    input,
    textarea {
        &:focus-visible {
            box-shadow: none !important;
            border: none !important;
            outline: 1px solid #ccc;
        }
    }

    .css-b62m3t-container {

        .css-13cymwt-control,
        .css-t3ipsp-control {
            border: 1px solid #ccc;
            box-shadow: none;
        }

        &:hover {
            border: none;
        }

        span.css-1u9des2-indicatorSeparator {
            display: none;
        }
    }
}

.over--scroller {
    &::-webkit-scrollbar {
        width: 5px;
        height: 5px;
    }

    &::-webkit-scrollbar-track {
        background: transparent;
    }

    &::-webkit-scrollbar-thumb {
        background: #888;
    }

    &::-webkit-scrollbar-thumb:hover {
        background: #555;
    }
}


.custom--checkbox {
    position: relative;

    & input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 30px;
        width: 30px;
        left: 0;
        top: 0;
        z-index: 1;
    }

    .checkmark--custom {
        height: 30px;
        width: 30px;
        background-color: #E4E4E4;
        border-radius: 5px;

        &:after {
            content: "";
            position: absolute;
            display: none;
        }
    }

    &:hover input~.checkmark--custom {
        background-color: #ccc;
    }

    & input:checked~.checkmark--custom {
        background-color: var(--black);
    }

    & input:checked~.checkmark--custom:after {
        display: block;
    }

    & .checkmark--custom:after {
        left: 11px;
        top: 6px;
        width: 8px;
        height: 14px;
        border: solid var(--white);
        border-width: 0 3px 3px 0;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
    }
}


input.arrow--none::-webkit-outer-spin-button,
input.arrow--none::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.auto--fill--none {
    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    input:-webkit-autofill:active {
        -webkit-box-shadow: 0 0 0 30px white inset !important;

    }
}

.chat--screen {
    position: fixed;
    bottom: 0px;
    right: 3%;
    height: 90%;
    width: 90% !important;
    max-width: 865px !important;
    animation: chatanimation 0.3s;
}

@keyframes chatanimation {
    from {
        transform: translateX(calc(100% + 7%))
    }

    to {
        transform: translateX(0%)
    }
}

.logo--login {
    padding-left: 15px;
    padding-bottom: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.login--form--admin {
    &:after {
        content: '';
        width: 100%;
        height: 100%;
        position: fixed;
        background: var(--white);
        left: 0;
        top: 0px;
    }

    form {
        z-index: 1;
        position: relative;
        border: 1px solid #d5d5d5;
        padding: 42px 15px;
        border-radius: 15px;
        padding-bottom: 20px;
    }
}

.heading--login{
    background: #fff;
    color: #000;
    width: 100%;
    max-width: 190px;
    margin: 0 auto;
    margin-bottom: -25px;
    z-index: 1;
    position: relative;
    border-radius: 5px;
    font-size: 17px;
    font-weight: 500;
    min-height: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    line-height: normal;
    border: 1px solid #d5d5d5;
}

.min-h{
    @media(max-width:1024px){
        height: 40px;
    }
}

.pagination--container {
    position: fixed;
    bottom: 0px;
    width: calc(100% - 366px);
    right: 50px;
    padding: 10px 15px;

    @media(max-width:1024px){
        width: calc(100% - 162px);
    right: 50px;
    }

    @media(max-width:991px){
        width: calc(100% - 92px);
    right: 15px;
    }
}