.profile--info--work{
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(185px, 1fr));
    gap: 5px;
    margin-bottom: 30px;

    .profile-info-item {
        border: 1px solid #efefef;
        padding: 15px;
        border-radius: 5px;

        h5 {
            margin-bottom: 7px;
        }

        img {
            margin-bottom: 5px;
        }
    }
}

.profile-comments--item {
    border: 1px solid #efefef;
    padding: 5px;
    background: #fbfbfb;
}